html,
body,
#root {
  min-height: 100vh !important;
  min-width: 100vw !important;
}
html,
body {
  background-image: linear-gradient(90deg, #f5f5f5 50%, #ffffff 100%);
}

#root > .container:not(.busca) > .columns {
  min-height: 101vh;
}
#root > .container:not(.busca) > .columns > .column.is-one-quarter {
  background-color: #f5f5f5;
}
#root > .container:not(.busca) > .columns > .column:not(.is-one-quarter) {
  background-color: #ffffff;
}

.has-text-orange {
  color: orangered;
}
.has-background-orange {
  background-color: orangered;
}
.message.is-orange .message-body {
  border-color: orangered;
}
.tag:not(body).is-orange {
  background-color: rgb(255, 68, 0);
  color: #ffffff;
}
.tag:not(body).is-orange.is-light {
  background-color: rgba(255, 68, 0, .25);
  color: #000000;
}
body.dark .tag:not(body).is-orange.is-light {
  color: rgb(255, 68, 0);
  background-color:rgb(255,255,255);
}

.tag:not(body).is-dark.is-light {
  background-color: rgba(0, 0, 0, .25);
  color: #000000;
}
body.dark .tag:not(body).is-dark.is-light {
  background-color: rgb(255, 255, 255);
  color: #000000;
}

.lista-estrutura > .message .message-body {
  background-color: #f5f5f5;
  transition: 0.3s;
}
.lista-estrutura > .message:hover .message-body {
  background-color: #ffffff;
}

.message .message-body a {
  text-decoration: none !important;
}

.nav-pathway > .message .message-body a .is-wrapped {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-pathway > .message .message-body a,
.lista-estrutura > .message .message-body a {
  display: block;
  transition: 0.3s;
}
.lista-estrutura > .message .message-body:hover a {
  color: #000000 !important;
}

hr {
  background-color: #e0e0e0;
}

.input ~ .icon,
.select ~ .icon,
.input ~ .icon,
.select ~ .icon {
  color: #999999 !important;
}
.input:focus ~ .icon,
.select:focus ~ .icon,
.input:focus ~ .icon,
.select:focus ~ .icon {
  color: #121212 !important;
}

.input::placeholder {
  color: #666666 !important;
}
.input:focus::placeholder {
  color: #121212 !important;
}

#organograma {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button.is-orange.is-outlined {
  background-color: transparent;
  border-color: orangered !important;
  color: orangered !important;
}
.button.is-orange.is-outlined:hover {
  background-color: orangered;
  border-color: orangered;
  color: white !important;
}
