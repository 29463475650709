htlm.dark,
body.dark {
  background-image: linear-gradient(90deg, #393939 50%, #323232 100%);
}
body.dark,
body.dark .title {
  color: #f5f5f5;
}

body.dark #root > .container:not(.busca) > .columns > .column.is-one-quarter {
  background-color: #393939;
}
body.dark
  #root
  > .container:not(.busca)
  > .columns
  > .column:not(.is-one-quarter) {
  background-color: #323232;
}

body.dark .is-orange.has-background-orange {
  background-color: orangered !important;
}
body.dark .has-background-link .has-text-white,
body.dark .has-background-orange .has-text-white {
  color: #f5f5f5 !important;
}

body.dark .message.is-dark,
body.dark .message.is-orange,
body.dark .message.is-link,
body.dark .message.is-danger,
body.dark .message.is-warning,
body.dark .message.is-primary {
  background-color: #393939;
}
body.dark .message.is-dark .message-body {
  border-color: #cccccc !important;
}

body.dark .message.has-background-white {
  background-color: #323232 !important;
}
body.dark .message.has-background-white a {
  color: #cccccc !important;
}

body.dark .has-background-light {
  background-color: #393939 !important;
}
body.dark .has-text-dark {
  color: #f5f5f5 !important;
}
body.dark .has-text-white {
  color: #323232 !important;
}
body.dark .has-background-dark {
  background-color: #f5f5f5 !important;
}

body.dark .lista-estrutura > .message .message-body {
  background-color: #393939;
  transition: 0.3s;
}
body.dark .lista-estrutura > .message .message-body a {
  color: #cccccc !important;
}
body.dark .lista-estrutura > .message:hover .message-body {
  background-color: #323232;
}
body.dark .lista-estrutura > .message:hover .message-body a {
  color: #f5f5f5;
}

body.dark .input {
  background-color: #393939;
  border-color: #bbbbbb;
  border-radius: 4px;
  color: #cccccc;
}

body.dark .has-text-dark {
  color: #cccccc !important;
}

body.dark hr {
  background-color: #424242;
}

body.dark .input ~ .icon,
body.dark .select ~ .icon,
body.dark .input ~ .icon,
body.dark .select ~ .icon {
  color: #cccccc !important;
}
body.dark .input:focus ~ .icon,
body.dark .select:focus ~ .icon,
body.dark .input:focus ~ .icon,
body.dark .select:focus ~ .icon {
  color: #f5f5f5 !important;
}

body.dark .input::placeholder {
  color: #cccccc !important;
}

body.dark .button.is-outlined {
  background-color: #393939;
  border-color: #cccccc;
  border-width: 1px;
  color: #cccccc;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  white-space: nowrap;
}

body.dark #organograma {
  background-color: #323232;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

body.dark .checkbox:hover, body.dark .radio:hover {
    color: #ddd;
}